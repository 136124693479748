<template>
  <div
    :class="{
      'osk-user-avatar': true,
      [`osk-user-avatar_size-${props.size}`]: !!props.size,
      'osk-user-avatar_increasing': props.increasing
    }"
  >
    <picture
      v-if="user.avatarPath"
      class="osk-user-avatar__picture"
    >
      <source
        :srcset="getImgPath(user.avatarPath)"
        type="image/webp"
      >
      <img
        class="osk-user-avatar__image"
        :src="user.avatarPath"
        alt=""
        loading="lazy"
        @error="onImgError"
      >
    </picture>

    <template v-else>
      {{ firstCharComp }}
    </template>
  </div>
</template>

<script lang="ts" setup>
import { UserDTO } from '~/restAPI/data-contracts';

type TAvatarSize = 'xs' | 's' | 'm' | 'ml' | 'l' | 'xl';

const { onImgError, getImgPath } = useImgCdnPath();

const props = withDefaults(defineProps<{
  user: UserDTO
  size?: TAvatarSize
  increasing?: boolean
}>(), {
  size: 's',
});

const firstCharComp = computed(() => props.user.firstChar ?? props.user.name?.slice(0, 1) ?? 'U');
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-user-avatar {
  margin-right: $indent-small;
  display: flex;
  user-select: none;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 100%;
  background-color: $black;
  font-size: $font-size-subheading;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  color: $white;
  flex-shrink: 0;

  &__picture {
    width: 100%;
    height: 100%;
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
  }

  &_size-xs {
    width: 24px;
    height: 24px;
    margin-right: 0;
  }

  &_size-s {
    width: 40px;
    height: 40px;
    margin-right: $indent-small;

    @media screen and (min-width: 580px) {
      width: 32px;
      height: 32px;
      margin-right: $indent-compact;
    }
  }

  &_size-ml {
    width: 64px;
    height: 64px;
    margin-right: $indent-small;

    @media screen and (min-width: 580px) {
      width: 64px;
      height: 64px;
      margin-right: $indent-compact;
    }
  }

  &_size-l {
    width: 80px;
    height: 80px;
    margin-right: $indent-small;

    @media screen and (min-width: 580px) {
      width: 80px;
      height: 80px;
      margin-right: $indent-compact;
    }
  }

  &_size-m {
    width: 40px;
    height: 40px;
    margin-right: $indent-compact;
  }

  &_increasing {
    width: 174px;
    height: 174px;
  }
}
</style>
